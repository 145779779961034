// BEM style modifiers for the most generic cases, e.g. single property of HTML element
// Useful for programmatic switching on/off as well as rapid development prototyping

// Element classes should be the name of the element preceded with a dot and all modifiers for it put inside

@import "scss/constants.scss";

// set some generic maps and enums here for use in props
$text-align-enum: (left, center, right);
$box-side-enum: (top, right, bottom, left);
$spacing-map: (
  xs: $spacing--xs,
  sm: $spacing--sm,
  md: $spacing--md,
  lg: $spacing--lg,
  xl: $spacing--xl,
  xxl: $spacing--xxl,
);

// generic placeholder for any element
// helps to not duplicate .div--text-left, .input--text-left...
.mod {
  &--not-implemented,
  &--hidden {
    display: none;
    visibility: hidden;
  }

  &--anamorphic {
    aspect-ratio: 2.39;
  }

  &--sixteen-nine {
    aspect-ratio: 16 / 9;
  }

  &--flex-row {
    display: flex;
    flex-direction: row;
  }

  &--flex-col {
    display: flex;
    flex-direction: column;
  }

  &--margin-centered {
    margin-left: auto;
    margin-right: auto;
  }

  &--text {
    @each $where in $text-align-enum {
      &-#{$where} {
        text-align: $where;
      }
    }
  }

  @for $step from 1 through 20 {
    &--opacity-#{5 * $step } {
      opacity: #{$step/20};
    }

    &--width-#{5 * $step}pp {
      width: 5 * $step * 1%;
    }

    &--width-#{2 * $step}em {
      width: 2 * $step * 1em;
    }

    &--width-#{2 * $step}rem {
      width: 2 * $step * 1rem;
    }

    &--height-#{5 * $step}pp {
      height: 5 * $step * 1%;
    }

    &--height-#{$step}em {
      height: $step * 1em;
    }

    &--height-#{$step}rem {
      height: $step * 1rem;
    }

    @each $side in $box-side-enum {
      &--margin-#{$side}-#{$step}rem {
        margin-#{$side}: $step * 1rem;
      }
    }

    &--font-weight-#{50 * $step} {
      font-weight: 50 * $step;
    }

    &--line-height-#{10 * $step + 5} {
      line-height: 0.1 * $step + 0.5;
    }
  }
}
